














































































import {Component} from 'vue-property-decorator';
import {dispatchDeletePackage, dispatchGetPackagesForList} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component({components: {ConfirmationModal}})
export default class Package extends AppComponent {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'panels',
      sortable: true,
      value: 'panels',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingPackagesData = false;

  /**
   * it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetch data asyncrhonously from the server
   * and displays loading spinner
   */
  public async fetchData() {
    this.fetchingPackagesData = true;
    await this.$nextTick();
    await dispatchGetPackagesForList(this.$store);
    this.fetchingPackagesData = false;
  }

  /**
   * Delete a package and refresh the screen
   */
  public async deletePackage(packageId: string) {
    await dispatchDeletePackage(this.$store, {packageId});
    this.fetchData();

    this.$forceUpdate();
  }
}
